import React from 'react';
import HeroSection from '../../components/auth/HeroSection';
import ChangePasswordForm from '../../components/auth/ChangePasswordForm';

const ChangePasswordPage = () => {
    return (
        <div className="block h-full mx-auto">
            <ChangePasswordForm />
        </div>
    );
};

export default ChangePasswordPage;